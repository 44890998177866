<template>
  <b-button
      :variant="variant"
      :type="type"
      :disabled="isDisable"
      @click="$emit('click')"
      :block="isBlock"
      :size="size"
  >
    <b-spinner
        v-if="isLoading"
        class="mr-50"
        small
    />
    <i v-else-if="icon !== ''" :class="`${icon} mr-50`"/>
    <span class="align-self-center">{{ label }}</span>
  </b-button>
</template>

<script>
export default {
  name: "LoadingButton",
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Submit",
    },
    icon: {
      type: String,
      default: "",
    },
    type: {
      type: String
    },
    variant: {
      type: String,
      default: "primary",
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    }
  },
}
</script>

<style scoped>

</style>
