<template>
  <b-form-group>
    <label>{{ label }}</label>
    <b-input-group>
      <b-form-input
          :id="id"
          :placeholder="placeholder"
          :value="value"
          :type="type"
          @input="updateValue"
      />
      <b-input-group-append v-if="labelButton">
        <loading-button variant="primary" :label="labelButton" :is-disable="true"/>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>

import LoadingButton from "@/views/components/LoadingButton.vue";

export default {
  name: "CustomInput",
  components: {
    LoadingButton,
  },
  props: {
    id: String,
    label: String,
    type: {
      type: String,
      default: "text",
    },
    labelButton: {
      type: String,
      default: undefined,
    },
    value: [String, Number],
    placeholder: String,

  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
}
</script>

<style scoped>

</style>
