<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <cleave
        :value="value"
        ref="moneyBox"
        class="form-control"
        :class="`text-${variant} font-weight-bold`"
        :raw="true"
        :options="cleaveOptions.number"
        @input="updateValue"
    />
  </div>
</template>

<script>

import Cleave from 'vue-cleave-component'

export default {
  name: "MoneyBox",
  components: {
    Cleave
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    label: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    currencyChar: {
      type: String,
      default: '₫'
    }
  },
  computed: {
    variant () {
      if ([undefined, null].includes(this.color)) {
        if (![undefined, null, ''].includes(this.value)) {
          if (parseInt(this.value) > 0) return 'success'
          else if (parseInt(this.value) < 0) return 'danger'
          else return 'secondary'
        } else {
          return 'secondary'
        }
      } else {
        return this.color
      }
    }
  },
  data () {
    return {
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ",",
          delimiter: ".",
          prefix: ` ${this.currencyChar}`,
          tailPrefix: true,
          rawValueTrimPrefix: true,
        }
      }
    }
  },
  methods: {
    updateValue (value) {
      if (value === '') {
        this.$emit('input', 0)
      } else {
        this.$emit('input', parseInt(value))
      }
    }
  }
}
</script>

<style scoped>

</style>
